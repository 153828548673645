<template>
	<div class="global-processing-overlay">
<!-- 
        <div class="modal-content shadow">
            <div class="modal-body p-4 pt-5 px-5">

                <original-yellow-title type='center'>
                    Processing
                </original-yellow-title>
                <p class="text-center">
                    Please wait for this action to finish.
                </p>
            </div>
        </div> -->

            <div class="overlay-text">
				<div class="modal-content shadow">
					<div class="modal-body p-4 px-5">
					<h3 class="text-center">
						Please wait
					</h3>
					<div class="lds-ellipsis-container text-center">
						<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
					</div>
				</div>
				</div>
            </div>

	</div>
</template>





<script>
export default {

}
</script>





<style scoped>
.global-processing-overlay 
{
	position: fixed; /* Sit on top of the page content */
	display: block;
	width: 100%; /* Full width (cover the whole page) */
	height: 100%; /* Full height (cover the whole page) */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5); /* Black background with opacity */
	/*z-index: 2;*/ /* Specify a stack order in case you're using a different order for other elements */
	z-index: 100;	/* May need a bit more if we have other z-indexes. Already had one conflict with a sticky element */
	cursor: pointer; /* Add a pointer on hover */
}


.global-processing-overlay .overlay-text
{
  position: absolute;
  top: 50%;
  left: 50%;
  /* font-size: 50px; */
  /* color: white; */
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);

}

.global-processing-overlay .overlay-text modal-content
{
	width: 50%;
	margin-left: auto;
	margin-right: auto;
}





.lds-ellipsis-container
{
	overflow: inherit;
}
/*
Source: https://loading.io/css/
*/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}
.lds-ellipsis div {
  position: absolute;
  top: 13px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

</style>